import axios from '@axios'

export default {
  namespaced: true,
  state: {
    items: [],
  },
  getters: {},
  mutations: {
    ITEMS(state, value) {
      state.items = value
    },
  },
  actions: {
    scrapeItems(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/scrape', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    scrapeItemsOrders(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/scrape_orders', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchItems(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/libraries', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchItem(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/item/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addItem(ctx, itemData) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/libraries',
          data: itemData,
          // eslint-disable-next-line no-unused-vars
          validateStatus: status => true,
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteItem(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/libraries/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
