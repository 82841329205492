<template>
  <v-combobox
    v-model="item"
    dense
    outlined
    :loading="searching"
    clearable
    hide-details
    :label="label"
    :disabled="disabled"
    :placeholder="label || ''"
    :prepend-inner-icon="icons.mdiCalendarRange"
    :items="places"
    item-value="display_name"
    item-text="display_name"

    :search-input.sync="search"
    return-object
  ></v-combobox>
</template>
<script>
import Vue from 'vue'
import {
  mdiClose, mdiPencilOutline, mdiPencilOffOutline, mdiCalendar, mdiMap,
} from '@mdi/js'
import { computed, ref, watch } from '@vue/composition-api'
import { format, parse } from 'date-fns'

import VueGoogleMap from 'vuejs-google-maps'
import axios from "@axios";

Vue.use(VueGoogleMap, {
  load: {
    apiKey: 'YOUR_API_KEY',
  },
})
const DEFAULT_DATE_FORMAT = 'MM/dd/yyyy'
export default {
  model: {
    prop: 'address',
    event: 'input',
  },
  props: {
    address: {
      type: String,
      default: () => '',
    },
    latitude: {
      type: Number,
      default: () => 0.0,
    },
    longitude: {
      type: Number,
      default: () => 0.0,
    },
    addresses: {
      type: Array,
      default: () => [],
    },
    addressChanged: {
      type: Function,
      default: () => {

      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const searching = ref(false)
    const timeout = ref(false)
    const places = ref([])
    const search = ref(null)
    const item = computed({
      get: () => props.address,
      set: value => {
        console.log('va', value)
        if(value) {
          emit('changed', value)
        }

      },
    })

    const onSearch = val => {
      try {
        clearTimeout(timeout.value)
      } catch (e) {
      }

      /* if (searching.value) {
        return false
      } */
      searching.value = true

      timeout.value = setTimeout(() => {
        /* const service = new vueGoogleMapsInit.maps.places.AutocompleteService();
        service.getQueryPredictions({ input: val }, (predictions, status) => {
          if (status != google.maps.places.PlacesServiceStatus.OK) {
            return;
          }

          this.places = predictions.map(prediction => {
            return {
              id: prediction.id,
              description: prediction.description,
            };
          });
        }); */
        axios.get('/places',
          {
            params: {
              term: val,
            },
          }).then(response => {
          console.log(response.data)
          for (let i = 0; i < response.data.length; i++) {
            const u = response.data[i]

            // console.log("u",u)
            const indexU = places.value.findIndex(el => el.place_id === u.place_id)
            console.log('index', indexU)
            if (indexU < 0) {
              places.value.push(u)
            }
          }
          if(response.data.length === 1) {
            emit('changed', response.data[0])
          }
          /*item.value = null
          search.value = null*/
        }).then(() => {
          searching.value = false
        }).catch(e => {
          console.log('eror', e)
        })

        return true
        axios.get('https://nominatim.openstreetmap.org', {
          params: {
            format: 'jsonv2',
            q: val,
          },
        }).then(response => {
          console.log(response.data)

          for (let i = 0; i < response.data.length; i++) {
            const u = response.data[i]

            // console.log("u",u)
            const indexU = places.value.findIndex(el => el.place_id === u.place_id)
            console.log('index', indexU)
            if (indexU < 0) {
              places.value.push(u)
            }
          }
        }).then(() => {
          searching.value = false
        })
      }, 2000)
    }

    watch(search, val => {
      clearTimeout(timeout.value)

      console.log('value ', val)

      // emit('changed', item.value)
      // props.addressChanged(item.value)
      // if (!searching.value) {
      onSearch(val)

      // }
    })

    return {
      search,
      searching,
      item,
      places,
      icons: {
        mdiMap,
      },
    }
  },
}
</script>
