<template>
  <v-form
    ref="form"
    v-model="valid"
    @submit.prevent="onSubmit"
  >
    <v-card>
      <v-card-title>
        <slot name="form-title">
          <span class="font-weight-semibold text-base text--primary">Ajouter un nouvel auteur</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="$emit('update:is-form-active',false)"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </slot>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="item.name"
                outlined
                dense
                :error-messages="errorMessages.name"
                :label="$t('Nom')"
                :placeholder="$t('Nom')"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="item.code"
                outlined
                dense
                :error-messages="errorMessages.code"
                :label="$t('Code insee')"
                :placeholder="$t('Code insee')"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="item.siret"
                outlined
                dense
                :error-messages="errorMessages.siret"
                :label="$t('Siret')"
                :placeholder="$t('Siret')"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="item.address"
                outlined
                dense
                :rules="[validators.required]"
                :error-messages="errorMessages.address"
                :label="$t('Adresse')"
                :placeholder="$t('Adresse')"
                hide-details="auto"
              ></v-text-field>
<!--              <GmapAutocomplete
                @place_changed="addressChanged"
                @query_changed="queryChanged"
              >
                <template v-slot:default="slotProps">
                  <v-text-field v-model="item.address" outlined
                                dense
                                clearable
                                hide-details
                                :label="$t('Adresse')"
                                :placeholder="$t('Adresse')"
                                :prepend-inner-icon="icons.mdiMagnify"
                                placeholder="Tapez votre adresse ici..."
                                ref="input"
                                v-on:listeners="slotProps.listeners"
                                v-on:attrs="slotProps.attrs">
                  </v-text-field>
                </template>
              </GmapAutocomplete>-->
            </v-col>
<!--            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-text-field
                v-model="item.address"
                outlined
                dense
                :rules="[validators.required]"
                :error-messages="errorMessages.address"
                :label="$t('Adresse')"
                :placeholder="$t('Adresse')"
                hide-details="auto"
              ></v-text-field>
              <PlacePicker
                v-model="item.address"
                outlined
                dense
                :rules="[validators.required]"
                :error-messages="errorMessages.address"
                :label="$t('Adresse')"
                :placeholder="$t('Adresse')"
                hide-details="auto"
                @changed="addressChanged"
              />
            </v-col>-->
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="item.complement"
                outlined
                dense
                :error-messages="errorMessages.code"
                :label="$t('Cpl')"
                :placeholder="$t('Cpl')"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-autocomplete
                v-model="item.country_id"
                :label="$t('Pays')"
                :rules="[validators.required]"
                :error-messages="errorMessages.country_id"
                :items="$store.state['app'].pays"
                item-text="name"
                item-value="id"
                outlined
                dense
                hide-details="auto"
                clearable
              >
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="item.city"
                outlined
                dense
                :rules="[validators.required]"
                :error-messages="errorMessages.city"
                :label="$t('Ville')"
                :placeholder="$t('Ville')"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="item.commune"
                outlined
                dense
                :error-messages="errorMessages.commune"
                :label="$t('Commune')"
                :placeholder="$t('Commune')"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="item.postal_code"
                outlined
                dense
                :error-messages="errorMessages.postal_code"
                :label="$t('Code postal')"
                :placeholder="$t('Code postal')"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="item.latitude"
                outlined
                dense
                :error-messages="errorMessages.latitude"
                :label="$t('Latitude')"
                :placeholder="$t('Latitude')"
                hide-details="auto"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="item.longitude"
                outlined
                dense
                :error-messages="errorMessages.longitude"
                :label="$t('Longitude')"
                :placeholder="$t('Longitude')"
                hide-details="auto"
                type="number"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <slot name="form-actions">
          <slot name="form-cancel">
            <v-btn
              :loading="loading"
              color="secondary"
              outlined
              type="reset"
              @click="resetItem"
            >
              {{ $t('Annuler') }}
            </v-btn>
          </slot>
          <v-spacer />
          <v-btn
            :loading="loading"
            color="primary"
            class="me-3"
            type="submit"
          >
            {{ $t("Enregistrer") }}
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import GmapVue from 'gmap-vue'
import { mdiClose, mdiPencilOutline, mdiPencilOffOutline,mdiMagnify } from '@mdi/js'
import { computed, ref, watch } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import store from '@/store'
import controller from './Controller'
import AvatarName from '@/components/AvatarName'
import PlacePicker from '@/components/PlacePicker.vue'
import Vue from "vue";

window.MyCustomCallback = () => { console.info('MyCustomCallback was executed') }
Vue.use(GmapVue, {
  load: {
    // [REQUIRED] This is the unique required value by Google Maps API
    key: 'AIzaSyC8_8YmWMNDqCSHzFI9A_7Xg5Miugh6rqo',
    language: 'fr',

    // [OPTIONAL] This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
    libraries: 'places',

    // [OPTIONAL] If you want to set the version, you can do so:
    v: '3.26',

    // This option was added on v3.0.0 but will be removed in the next major release.
    // If you already have an script tag that loads Google Maps API and you want to use it set you callback
    // here and our callback `GoogleMapsCallback` will execute your custom callback at the end; it must attached
    // to the `window` object, is the only requirement.
    customCallback: 'MyCustomCallback',
  },

  // [OPTIONAL] If you intend to programmatically custom event listener code
  // (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  // instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  // you might need to turn this on.
  autoBindAllEvents: false,

  // [OPTIONAL] If you want to manually install components, e.g.
  // import {GmapMarker} from 'gmap-vue/src/components/marker'
  // Vue.component('GmapMarker', GmapMarker)
  // then set installComponents to 'false'.
  // If you want to automatically install all the components this property must be set to 'true':
  installComponents: true,

  // Load the Google Maps API dynamically, if you set this to `true` the plugin doesn't load the Google Maps API
  dynamicLoad: false,
})
export default {
  components: { },
  model: {
    prop: 'object',
    event: 'update:is-form-active',
  },
  props: {
    object: {
      type: Object,
      default: () => {
      },
    },
    isFormActive: {
      type: Boolean,
      required: true,
    },
    withPassword: {
      type: Boolean,
      default: true,
    },
    roleOptions: {
      type: Array,
      default: () => [],
    },
    planOptions: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const {
      form,
      isSnackbarBottomVisible,
      snackBarMessage,
    } = controller()
    const errorMessages = ref({})
    const valid = ref(false)
    const loading = ref(false)
    const isLoadingCountries = ref(false)
    const validate = () => {
      form.value.validate()
    }

    const authors = ref([])
    const isLoadingAuthors = ref(false)
    const search = ref(null)
    const address = ref('')

    const item = computed({
      get: () => props.object,
      set: value => emit('update:object', value),
    })

    const searchAuthors = term => {
      store.dispatch('app/searchAuthors', { term, rowsPerPage: 100 }).then(response => {
        isLoadingAuthors.value = false
        for (let i = 0; i < response.data.data.length; i++) {
          const u = response.data.data[i]
          const indexU = authors.value.findIndex(el => el.id === u.id)
          if (indexU < 0) {
            authors.value.push(u)
          }
        }
      })
    }

    const addressChanged = value => {

      console.log('baba', value)
      item.value.libraries = [value.display_name, value.name, ...value.display_name.split(',')]
      item.value.latitude = parseFloat(value.lat)
      item.value.longitude = parseFloat(value.lon)
      item.value.address = value.display_name
      console.log('geomentry', { lat: value.geometry.location.lat(), lng: value.geometry.location.lng() })
      console.log('address_components', value.address_components)
      console.log('formated_address', value.formatted_address)
      console.log('formated_address', value.formatted_address)
      console.log('name', value.name)
      console.log('place_id', value.place_id)
      console.log('vicinity',value.vicinity)

      console.log(e)
    }
    const addressChanged1 = e => {
      console.log('geomentry', { lat: e.geometry.location.lat(), lng: e.geometry.location.lng() })
      console.log('address_components', e.address_components)
      console.log('formated_address', e.formatted_address)
      console.log('formated_address', e.formatted_address)
      console.log('name', e.name)
      console.log('place_id', e.place_id)
      console.log('vicinity', e.vicinity)

      console.log(e)

      /* addressChanged({lat: e.geometry.location.lat(), lng: e.geometry.location.lng() }) */
      /* emit('changed', {lat: e.geometry.location.lat(), lng: e.geometry.location.lng() }) */

     /* const c = {
        lat: e.geometry.location.lat(),
        lng: e.geometry.location.lng(),
        marker: {
          id: `${e.place_id}`,
          position: { lat: e.geometry.location.lat(), lng: e.geometry.location.lng() },
          tooltip: e.formatted_address,
          draggable: false,
          visible: true,
          label: e.name,
          address: e.formatted_address,
          email: '',
          tel: '',
          avatar: iconUrl,
        },
      }

      markers.value = [c.marker]
      latitude.value = e.geometry.location.lat()
      longitude.value = e.geometry.location.lng()
      center.value = c
      fitPolyline()*/
    }

    if (item.value.author && authors.value.findIndex(ele => ele.id === item.value.author_id) < 0) {
      authors.value.push(item.value.author)
    }
    watch(item, val => {
      if (item.value.author && authors.value.findIndex(ele => ele.id === item.author_id) < 0) {
        authors.value.push(item.value.author)
      }
    }, { deep: true })

    watch(search, val => {
      if (!val || val.trim().length < 3 || isLoadingAuthors.value) return ''
      isLoadingAuthors.value = true
      searchAuthors(val)

      return ''
    })

    // const item = ref(JSON.parse(JSON.stringify(blankItem)))
    const resetItem = () => {
      // item.value = {}

      // emit('update:object', item.value)
    }

    const onSubmit = () => {
      if (valid.value) {
        loading.value = true
        store.dispatch('library/addItem', item.value)
          .then(response => {
            loading.value = false
            if (response.data.error || response.data.errors || (response.data.status && response.data.status === 'false')) {
              if (response.data.errors) {
                // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
                errorMessages.value = { ...response.data.errors }
              } else {
                isSnackbarBottomVisible.value = true
                snackBarMessage.value = response.data.message

                // this.toast(response.data.message, this.$vuetify.theme.currentTheme.error)
              }

              return false
            }
            resetItem()

            // emit('update:is-form-active', false)

            emit('refetch-data')
          }).catch(error => {
            loading.value = false
            console.log('error', error)
            if (error.data.errors) {
            // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
              errorMessages.value = { ...error.data.errors }
            }
          })
      } else {
        validate()
      }
    }
    // eslint-disable-next-line no-unused-vars
    /* watch(() => props.object, (newValue, prevValue) => {
      if (!newValue || !newValue.id) {
        resetItem()
      } else {
        item.value = JSON.parse(JSON.stringify(newValue))
      }
    }) */
    // eslint-disable-next-line no-unused-vars
    /* watch(() => item, (newValue, prevValue) => {
      console.log('item change', newValue.value)
      emit('input', newValue.value)
    }, { deep: true }) */
    const queryChanged = e => {
      console.log(e.target.value)
    }
    return {
      addressChanged,
      queryChanged,
      resetItem,
      form,
      search,
      address,
      authors,
      isLoadingAuthors,
      isLoadingCountries,
      isSnackbarBottomVisible,
      snackBarMessage,
      errorMessages,
      onSubmit,
      item,
      valid,
      loading,
      validate,

      // validation
      validators: { required, emailValidator },
      icons: {
        mdiClose,
        mdiPencilOffOutline,
        mdiPencilOutline,
        mdiMagnify
      },
    }
  },
}
</script>
